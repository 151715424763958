<template>
    <!-- 添加设备弹窗 -->
    <a-modal
        :width="600"
        title="添加设备"
        :visible="visible"
        ok-text="确认"
        cancel-text="取消"
        @ok="onSubmit"
        v-on="$listeners">
        <div>
            <ul>
                <li>
                    <span style="margin-right: 30px">选择产品</span>
                    <a-select v-model="form.type" style="width: 200px" placeholder="选择产品">
                        <a-select-option v-for="item in productList" :key="item.id" :value="item.code">
                            {{ item.productName }}
                        </a-select-option>
                    </a-select>
                </li>
                <li style="margin: 20px 0">
                    <a-radio-group v-model="form.style" name="radioGroup" @change="onChoose">
                        <a-radio :value="1">
                            选择指定设备
                        </a-radio>
                        <a-radio :value="2">
                            选择产品下全部设备
                        </a-radio>
                        <a-radio :value="3">
                            列表导入
                        </a-radio>
                    </a-radio-group>
                </li>
                <li>
                    <ul>
                        <li v-if="index === 1 || index === 2" style="position: relative">
                            <div v-show="index === 1" class="searchWrap">
                                <span class="searchTitle">设备名称</span>
                                <a-input-search placeholder="请输入关键字搜索" />
                            </div>
                            <a-table class="equipNameList"
                                     :row-selection="{ selectedRowKeys: selectedName, onChange: onSelectName }"
                                     :locale="{emptyText: '暂无数据'}"
                                     table-layout="auto" :columns="equipColumns" row-key="id" :data-source="equipData" />
                            <span class="selectedItem">选中 {{ selectedName.length }} 项</span>
                        </li>
                        <li v-show="index === 3">
                            <p class="addInfo">
                                <span>批量添加设备可在批次列表中查询相关记录<br>格式.xlsx 最大2M，单次500个设备</span>
                                <span class="downLoad">模板下载</span>
                            </p>
                            <p style="margin: 30px 40px">
                                <a-upload
                                    name="file"
                                    :multiple="true"
                                    @change="handleUpload">
                                    <a-button>*上传设备表格</a-button>
                                </a-upload>
                            </p>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </a-modal>
</template>

<script>
import { productList } from "@/api/product"
const equipColumns = [
    {
        title: '设备名称',
        dataIndex: 'name',
        key: 'id'
    }
]
const equipData = [
    {
        id: '1',
        name: '设备1'
    },
    {
        id: '2',
        name: '设备2'
    }
]
export default {
    name: "AddEquip",
    props: {
        visible: {
            type:Boolean,
            default: false
        }
    },
    data() {
        return {
            productList: [],
            // 单个添加表单数据
            form: {
                style: undefined,
                type: undefined
            },
            // 添加设备弹窗中不同选择方式对应的内容
            index: -1,
            // 设备名称多选数据集合
            selectedName: [],
            equipColumns,
            equipData,
        }
    },
    created() {
        this.getProductList()
    },
    methods: {
        // 点击添加设备弹窗中的确定按钮
        onSubmit() {
            this.$emit('onSubmit')
        },
        // 获取产品列表数据
        getProductList() {
            productList().then(res => {
                this.productList = res.data
            }).catch(error => {
                console.log(error)
            })
        },
        onChoose(val) {
            this.index = val.target.value
            if(this.index === 2) {
                this.selectedName = ['1', '2']
            } else {
                this.selectedName = []
            }
        },
        // 设备名称中的多选事件
        onSelectName(val) {
            this.selectedName = val
        },
        // 批量添加弹窗中的上传设备表格
        handleUpload(val) {
            console.log(val)
        }
    }
}
</script>

<style scoped>

</style>
