var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    _vm._g(
      {
        attrs: {
          width: 600,
          title: "添加设备",
          visible: _vm.visible,
          "ok-text": "确认",
          "cancel-text": "取消",
        },
        on: { ok: _vm.onSubmit },
      },
      _vm.$listeners
    ),
    [
      _c("div", [
        _c("ul", [
          _c(
            "li",
            [
              _c("span", { staticStyle: { "margin-right": "30px" } }, [
                _vm._v("选择产品"),
              ]),
              _c(
                "a-select",
                {
                  staticStyle: { width: "200px" },
                  attrs: { placeholder: "选择产品" },
                  model: {
                    value: _vm.form.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "type", $$v)
                    },
                    expression: "form.type",
                  },
                },
                _vm._l(_vm.productList, function (item) {
                  return _c(
                    "a-select-option",
                    { key: item.id, attrs: { value: item.code } },
                    [_vm._v(" " + _vm._s(item.productName) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "li",
            { staticStyle: { margin: "20px 0" } },
            [
              _c(
                "a-radio-group",
                {
                  attrs: { name: "radioGroup" },
                  on: { change: _vm.onChoose },
                  model: {
                    value: _vm.form.style,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "style", $$v)
                    },
                    expression: "form.style",
                  },
                },
                [
                  _c("a-radio", { attrs: { value: 1 } }, [
                    _vm._v(" 选择指定设备 "),
                  ]),
                  _c("a-radio", { attrs: { value: 2 } }, [
                    _vm._v(" 选择产品下全部设备 "),
                  ]),
                  _c("a-radio", { attrs: { value: 3 } }, [
                    _vm._v(" 列表导入 "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("li", [
            _c("ul", [
              _vm.index === 1 || _vm.index === 2
                ? _c(
                    "li",
                    { staticStyle: { position: "relative" } },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.index === 1,
                              expression: "index === 1",
                            },
                          ],
                          staticClass: "searchWrap",
                        },
                        [
                          _c("span", { staticClass: "searchTitle" }, [
                            _vm._v("设备名称"),
                          ]),
                          _c("a-input-search", {
                            attrs: { placeholder: "请输入关键字搜索" },
                          }),
                        ],
                        1
                      ),
                      _c("a-table", {
                        staticClass: "equipNameList",
                        attrs: {
                          "row-selection": {
                            selectedRowKeys: _vm.selectedName,
                            onChange: _vm.onSelectName,
                          },
                          locale: { emptyText: "暂无数据" },
                          "table-layout": "auto",
                          columns: _vm.equipColumns,
                          "row-key": "id",
                          "data-source": _vm.equipData,
                        },
                      }),
                      _c("span", { staticClass: "selectedItem" }, [
                        _vm._v(
                          "选中 " + _vm._s(_vm.selectedName.length) + " 项"
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "li",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.index === 3,
                      expression: "index === 3",
                    },
                  ],
                },
                [
                  _c("p", { staticClass: "addInfo" }, [
                    _c("span", [
                      _vm._v("批量添加设备可在批次列表中查询相关记录"),
                      _c("br"),
                      _vm._v("格式.xlsx 最大2M，单次500个设备"),
                    ]),
                    _c("span", { staticClass: "downLoad" }, [
                      _vm._v("模板下载"),
                    ]),
                  ]),
                  _c(
                    "p",
                    { staticStyle: { margin: "30px 40px" } },
                    [
                      _c(
                        "a-upload",
                        {
                          attrs: { name: "file", multiple: true },
                          on: { change: _vm.handleUpload },
                        },
                        [_c("a-button", [_vm._v("*上传设备表格")])],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }